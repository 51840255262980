"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginRequest = exports.msalConfig = void 0;
const { Configuration } = require('@azure/msal-browser');
const redirectUri = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://qa-ms.spark.sonepar.com';
exports.msalConfig = {
    auth: {
        clientId: `${process.env.MSAL_CLIENT_ID}`,
        authority: `https://login.microsoftonline.com/${process.env.MSAL_TENANT_ID}`,
        redirectUri: redirectUri
    },
};
exports.loginRequest = {
    scopes: ['User.Read'],
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Opcos = void 0;
exports.Opcos = [
    'All',
    'AUTH_POC',
    'BEL-CEBEO',
    'FRA-CONNECT',
    'HUN-SONHUN',
    'ITA-HDUEA',
    'ITA-SACCHI',
    'ITA-SONITA',
    'NOR-SONNOR',
    'FIN-SONFIN',
    'USA-SPRFLD',
    'NLD-TECUNIE',
    'BEL-ROUTECO',
    'NLD-ROUTECO',
    'GBR-ROUTECO',
    'AUT-ROUTECO',
];
